import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app);

// Request Notification Permission
const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    console.log("Notification permission:", permission);

    if (permission === "granted") {
      console.log("Notification permission granted.");
      const token = await getToken(messaging, {
        vapidKey:
          "BM0zowZxXl3qcK31nahj2F-rmXTjBwgFTda3ffnLua7IUOkdkZs4jj1-qWVg8O9yrM11xHnaSGqGZTOaUi-sJjY",
      });
      console.log("FCM Token:", token);
    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error getting permission:", error);
  }
};

// Handle Foreground Notifications
const handleForegroundNotifications = () => {
  console.log("Listening for foreground messages...");
  onMessage(messaging, (payload) => {
    console.log("Foreground Notification Received:", payload);

    // Display an alert (replace with custom UI if needed)
    alert(`🔔 ${payload.notification.title}\n${payload.notification.body}`);
  });
};

const googleProvider = new GoogleAuthProvider();

export {
  auth,
  messaging,
  requestPermission,
  googleProvider,
  handleForegroundNotifications,
};
