import React, { useEffect, useState } from "react";
import axios from "axios";
import NotFound from "../UnknowRoute/NotFound";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// Example Navbar1 component
const Navbar1 = () => {
  const navigate = useNavigate(); // Correctly place the navigate hook here

  return (
    <nav className="bg-[#393285] text-white p-4 flex justify-between items-center">
      <h1 className="sm:text-2xl text-lg">Admin Page</h1>
      <span
        className="material-symbols-outlined text-[#4285F4] cursor-pointer text-[50px] "
        onClick={() => {
          Swal.fire({
            title: "Are you sure you want to log out?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, log out",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.isConfirmed) {
              // Clear local storage items
              localStorage.removeItem("type");
              localStorage.removeItem("token");

              // Show success message
              Swal.fire({
                title: "Logged out successfully",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
              });

              // Redirect to home page
              navigate("/");
            }
          });
        }}
      >
        logout
      </span>
    </nav>
  );
};

const CandidateCard = ({
  id,
  amount,
  companyName,
  communicationSkill,
  organizationAddress,
  jobTitle,
  jobLocation,
  jobState,
  jobCity,
  jobPincode,
  educationQualification,
  experienceFresher,
  interviewLocation,
  nameAndDesignation,
  mobileNumber,
  email,
  workType,
  interviewProcess,
  salary,
  workingHours,
  jobDescription,
  postedDate,
  status,
  gender,
  updateJobStatus,
  PropToEdit,
  profile, // Pass updateJobStatus as a prop
}) => {
  const navigate = useNavigate(); // Correctly place the navigate hook here
  const [showDesc, setShowDesc] = useState(false);

  const handleDesc = () => {
    setShowDesc((prev) => !prev);
  };

  const formattedDate = new Date(postedDate).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  const handleCheckResponses = (documentId, jobTitle) => {
    navigate(`/check_responses/${documentId}`, { state: jobTitle });
  };

  return (
    <div className="p-4 rounded-2xl shadow-md mb-4 bg-[#DFDCF7] ">
      {/* 
    <p className="text-gray-500 text-sm text-right mt-4 hidden">{appliedDate}</p> */}

      {/* --------------- new code here ------------- */}

      <div className="flex flex-col sm:gap-y-4  gap-y-3 smd:flex-row justify-between">
        <div className="smd:w-[85%]  flex flex-col gap-y-4">
          <div className="  flex smd:hidden justify-center">
            <img
              src={profile ? profile : "/profile.jpg"} // Adjusted fallback path
              alt="Profile"
              className="rounded-2xl mb-4 md:mr-4 h-12 w-12 md:h-16 md:w-16"
            />
          </div>

          <div className="flex flex-col gap-y-3 sm:flex-row justify-between">
            <div className="w-max  hidden smd:flex">
              <img
                src={profile ? profile : "/profile.jpg"} // Adjusted fallback path
                alt="Profile"
                className="rounded-2xl mb-4 md:mr-4 h-12 w-12 md:h-16 md:w-16"
              />
            </div>

            <div className="xlg:w-[29%] lg:w-[30%] smd:w-[46%] sm:w-[48%]  flex flex-col gap-y-3">
              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Company Name</p>
                <p>{companyName}</p>
              </div>

              <div className="flex lg:hidden flex-col ">
                <p className="font-semibold text-blue-200">Email</p>
                <p>{email}</p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Job Title</p>
                <p>{jobTitle}</p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Job Location</p>
                <p className=" flex flex-wrap gap-1">
                  <span>{jobLocation},</span>
                  <span>
                    {jobCity}-{jobPincode},
                  </span>
                  <span>{jobState}</span>
                </p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">
                  Education Qualification
                </p>
                <p>
                  {Array.isArray(educationQualification)
                    ? educationQualification
                      .map((data) =>
                        data === "10thPassed"
                          ? "10th Passed"
                          : data === "12thPassed"
                            ? "12th Passed"
                            : "Graduated and above"
                      )
                      .join(", ")
                    : educationQualification === "10thPassed"
                      ? "10th Passed"
                      : educationQualification === "12thPassed"
                        ? "12th Passed"
                        : "Graduated and above"}
                </p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Experience Level</p>
                <p>
                  {" "}
                  {Array.isArray(experienceFresher)
                    ? experienceFresher
                      .map((data) =>
                        data === "fresher"
                          ? "Fresher"
                          : data === "0-1yr"
                            ? "0-1 yr"
                            : data === "1-2yr"
                              ? "1-2 yr"
                              : "More than 2 yr"
                      )
                      .join(", ")
                    : experienceFresher === "fresher"
                      ? "Fresher"
                      : experienceFresher === "0-1yr"
                        ? "0-1 yr"
                        : experienceFresher === "1-2yr"
                          ? "1-2 yr"
                          : "More than 2 yr"}
                </p>
              </div>

              <div className="flex  flex-col ">
                <p className="font-semibold text-blue-200">
                  Communication Skills
                </p>
                {/* <p>{communicationSkill}</p> */}
                <span>
                  {Array.isArray(communicationSkill)
                    ? communicationSkill
                      .map((data) =>
                        data === "hindi"
                          ? "Hindi"
                          : data === "englishNormal"
                            ? "English (Normal Speaking)"
                            : data === "englishIntermediate"
                              ? "English (Intermediate)"
                              : data === "englishFluent"
                                ? "English (Fluent)"
                                : "Others"
                      )
                      .join(", ")
                    : communicationSkill === "hindi"
                      ? "Hindi"
                      : communicationSkill ===
                        "englishNormal"
                        ? "English (Normal Speaking)"
                        : communicationSkill ===
                          "englishIntermediate"
                          ? "English (Intermediate)"
                          : communicationSkill ===
                            "englishFluent"
                            ? "English (Fluent)"
                            : "Others"}
                </span>
              </div>

              <div className="flex lg:hidden flex-col ">
                <p className="font-semibold text-blue-200">Working Hours</p>
                <p>{workingHours} hrs</p>
              </div>

              <div className="flex lg:hidden flex-col ">
                <p className="font-semibold text-blue-200">
                  Interview Location:
                </p>
                {Array.isArray(interviewProcess) ? (
                  interviewProcess.includes("offline") ? (
                    <span>{interviewLocation}</span>
                  ) : (
                    <span>Remote</span>
                  ) // Display "Remote" for non-offline interviews
                ) : interviewProcess === "offline" ? (
                  <span>{interviewLocation}</span>
                ) : (
                  <span>Remote</span>
                )}{" "}
                {/* Display "Remote" when interview is not offline */}
              </div>
            </div>

            <div className="xlg:w-[29%] lg:w-[30%] smd:w-[46%] sm:w-[48%]  flex flex-col gap-y-3">
              <div className="flex  flex-col ">
                <p className="font-semibold text-blue-200">
                  Name & Designation
                </p>
                <p>{nameAndDesignation}</p>
              </div>

              <div className="lg:hidden flex flex-col ">
                <p className="font-semibold text-blue-200">Gender</p>
                <p className="flex flex-col gap-y-0">
                  {gender ? (
                    gender === "Both" ? (
                      "Male and Female"
                    ) : (
                      gender
                    )
                  ) : (
                    <span>Male and Female</span>
                  )}
                </p>
              </div>

              <div className="flex  flex-col ">
                <p className="font-semibold text-blue-200">Company Address</p>
                <p>{organizationAddress}</p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Mobile Number</p>
                <p>{mobileNumber}</p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Interview Process</p>
                <span>
                  {Array.isArray(interviewProcess)
                    ? interviewProcess.join(", ")
                    : interviewProcess}
                </span>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Work Type</p>
                <p>
                  {" "}
                  {Array.isArray(workType)
                    ? workType
                      .map((data) =>
                        data === "hybrid"
                          ? "Hybrid"
                          : data === "home"
                            ? "Work from Home"
                            : "Work from Office"
                      )
                      .join(", ")
                    : workType === "hybrid"
                      ? "Hybrid"
                      : workType === "home"
                        ? "Work from Home"
                        : "Work from Office"}
                </p>
              </div>
              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Amount Paid</p>
                <p>{amount === 0 ? "Free Post" : amount + "/-"}</p>
              </div>

              <div className="flex lg:hidden  flex-col ">
                <p className="font-semibold text-blue-200">Salary</p>
                <p>{salary}</p>
              </div>
            </div>

            <div className="xlg:w-[29%] w-[30%]  hidden lg:flex flex-col gap-y-3">
              <div className="flex  flex-col ">
                <p className="font-semibold text-blue-200">Email</p>
                <p>{email}</p>
              </div>

              <div className="flex  flex-col ">
                <p className="font-semibold text-blue-200">Working Hours</p>
                <p>{workingHours} hrs</p>
              </div>

              <div className="flex  flex-col ">
                <p className="font-semibold text-blue-200">
                  Interview Location:
                </p>
                {Array.isArray(interviewProcess) ? (
                  interviewProcess.includes("offline") ? (
                    <span>{interviewLocation}</span>
                  ) : (
                    <span>Remote</span>
                  ) // Display "Remote" for non-offline interviews
                ) : interviewProcess === "offline" ? (
                  <span>{interviewLocation}</span>
                ) : (
                  <span>Remote</span>
                )}{" "}
                {/* Display "Remote" when interview is not offline */}
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Gender</p>
                <p className="flex flex-col gap-y-0">
                  {gender ? (
                    gender === "Both" ? (
                      "Male and Female"
                    ) : (
                      gender
                    )
                  ) : (
                    <span>Male and Female</span>
                  )}
                </p>
              </div>

              <div className="flex  flex-col ">
                <p className="font-semibold text-blue-200">Salary</p>
                <p>{salary}</p>
              </div>
            </div>
          </div>

          {/* --------- job Description ----- */}

          <div className="smd:pl-20 xlg:pl-24 flex flex-col gap-y-2 ">
            <p className="font-semibold  text-blue-200">Job Description</p>

            <pre
              className="font-abeezee text-justify sm:text-base text-sm"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {jobDescription
                ? !showDesc
                  ? jobDescription.length > 200
                    ? jobDescription.slice(0, 200) + "... "
                    : jobDescription
                  : jobDescription + "... "
                : "No description available."}

              {jobDescription && jobDescription.length > 200 && (
                <span
                  onClick={handleDesc}
                  className="text-blue-300 cursor-pointer font-bold"
                >
                  {showDesc ? "show less" : "read more"}
                </span>
              )}
            </pre>
          </div>
        </div>

        <div className="smd:w-max  flex flex-col-reverse  smd:flex-col justify-between font-inter  gap-y-4">
          <div className="flex flex-col items-center  gap-y-3 ">
            {status !== "accepted" && (
              <button
                className="bg-white hover:bg-green-100 hover:text-white font-bold py-1 px-4 rounded-2xl h-9  md:py-1 smd:px-4  md:h-9 w-36 xs:w-48 smd:w-36 sm:py-0.5 sm:px-2 sm:h-8 transition-all duration-300"
                onClick={() => updateJobStatus(id, "accepted")}
              >
                Accept
              </button>
            )}
            {status === "accepted" && (
              <button className="bg-green-100 text-white font-bold py-1 px-4 rounded-2xl h-9  md:py-1 md:px-4 md:h-9 w-36 xs:w-48 smd:w-36 sm:py-0.5 sm:px-2 sm:h-8  cursor-not-allowed transition-all duration-300">
                Accepted
              </button>
            )}
            {status !== "rejected" && (
              <button
                className="bg-white hover:bg-red-100 hover:text-white font-bold py-1 px-4 rounded-2xl h-9 md:py-1 md:px-4 md:h-9 w-36 xs:w-48 smd:w-36 sm:py-0.5 sm:px-2 sm:h-8  transition-all duration-300"
                onClick={() => updateJobStatus(id, "rejected")}
              >
                Reject
              </button>
            )}
            {status === "rejected" && (
              <button className="bg-red-100  text-white font-bold py-1 px-4 rounded-2xl h-9  md:py-1 md:px-4 md:h-9 w-36 xs:w-48 smd:w-36 sm:py-0.5 sm:px-2 sm:h-8 s cursor-not-allowed transition-all duration-300">
                Rejected
              </button>
            )}

            <button
              className="bg-white hover:bg-blue-100 hover:text-white font-bold py-1 px-4 rounded-2xl h-9 w-36 xs:w-48 md:py-1 md:px-4 md:h-9 smd:w-36 sm:py-0.5 sm:px-2 sm:h-8  transition-all duration-300"
              onClick={() => PropToEdit(id, "edit")}
            >
              Edit
            </button>

            <button
              className={` h-max flex justify-center md:py-2 py-1 px-3 bg-white rounded-2xl transition-all duration-300 hover:scale-105 text-sm xs:text-base font-bold `}
              onClick={() => {
                handleCheckResponses(id, jobTitle);
              }}
            >
              Check Response
            </button>
          </div>

          <div className="flex flex-col w-full items-end  smd:items-center font-semibold text-sm  justify-end smd:justify-start">
            <p className="text-blue-200">Posted on</p>
            <p className="text-gray-500 text-[12px]">{formattedDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section5 = () => {
  const navigate = useNavigate();
  const type = localStorage.getItem("type");
  const uid = localStorage.getItem("token");
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allowRender, setAllowRender] = useState(false);

  // const [error, setError] = useState(null);
  const [filteredCandidates, setFilteredCandidates] = useState(candidates);

  const handleShowAll = () => {
    const approvedCandidates = candidates.filter(
      (candidate) => candidate.status === "waiting"
    );
    setFilteredCandidates(approvedCandidates);
  };

  useEffect(() => {
    handleShowAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidates]);

  const handleShowApproved = () => {
    const approvedCandidates = candidates.filter(
      (candidate) => candidate.status === "accepted"
    );
    setFilteredCandidates(approvedCandidates);
  };

  const handleShowRejected = () => {
    const rejectedCandidates = candidates.filter(
      (candidate) => candidate.status === "rejected"
    );
    setFilteredCandidates(rejectedCandidates);
  };

  useEffect(() => {
    setFilteredCandidates(candidates); // Update state when candidates change
  }, [candidates]);

  useEffect(() => {
    const fetchCandidates = () => {
      axios
        .post(
          `${process.env.REACT_APP_PORT}/api/getJobs`,
          {}, // Pass an empty object as the request body if no body is required
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${uid}`, // Include token for authentication
            },
          }
        )
        .then((response) => {
          setAllowRender(true);
          setCandidates(response.data);
        })
        .catch((err) => {
          if (!toast.isActive("invalid-credentials-toast")) {
            toast.error("Please Relogin Session error!", {
              toastId: "invalid-credentials-toast",
            });
          }
          localStorage.clear();
          navigate("/");
          // console.error("Error fetching candidates:", err);
        })
        .finally(() => setLoading(false));
    };

    fetchCandidates();
  }, [uid, navigate]);

  const updateJobStatus = (jobId, status) => {
    axios
      .patch(
        `${process.env.REACT_APP_PORT}/api/updateJob/${jobId}`,
        { status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${uid}`, // Add token for authentication
          },
        }
      )
      .then((response) => {
        setCandidates(
          candidates.map((candidate) =>
            candidate.id === jobId ? { ...candidate, status } : candidate
          )
        );
      })
      .catch((error) => {
        toast.error("Error updating job status:", error);
        console.error("Error updating job status:", error);
      });
  };

  const PropToEdit = (id, status) => {
    navigate(`/edit/${id}`, { state: { status } });
  };

  const approvedCount = candidates.filter(
    (candidate) => candidate.status === "accepted"
  ).length;
  const rejectedCount = candidates.filter(
    (candidate) => candidate.status === "rejected"
  ).length;

  if (!allowRender) {
    return null;
  }

  return (
    <>
      {type === "admin" && uid ? (
        <div>
          {loading ? (
            <HashLoader
              color="#34a5f3"
              size={60} // Adjust the size as needed
              cssOverride={{
                position: "absolute", // Ensure the loader is positioned relative to the viewport
                top: "50%", // Center vertically
                left: "50%", // Center horizontally
                transform: "translate(-50%, -50%)", // Adjust to truly center the loader
              }}
              loading={true}
            />
          ) : (
            <div>
              <Navbar1 />
              <div className="p-4 mx-auto -2 -green-100">
                <div className="flex flex-col md:flex-row items-center justify-between">
                  <button className="text-xl mb-4 hidden md:block">
                    &larr; Back
                  </button>
                  <h1
                    className="xmd:text-3xl sm:text-2xl xsm:text-xl text-lg flex-grow text-center"
                    style={{
                      color: "rgba(57, 50, 133, 1)",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      lineHeight: "33.89px",
                    }}
                  >
                    ADMIN-APPROVALS
                  </h1>
                  <div className="w-16"></div>{" "}
                  {/* Spacer div to balance the layout */}
                </div>
                <div className="mx-2 xs:mx-4 xmd:mx-8 xlg:mx-12 xl:mx-24 flex flex-col gap-y-4">
                  <div
                    className="flex flex-wrap xmd:text-xl sm:text-lg text-base my-4 text-black-400 py-2  rounded-full leading-6 justify-around -2 "
                    style={{
                      backgroundColor: "#DFDCF7",
                      Radius: "15px",
                      fontFamily: "ABeeZee",
                    }}
                  >
                    <button
                      className="bg-white py-1 px-4 rounded-2xl leading-8 hidden md:block"
                      onClick={handleShowAll}
                    >
                      Waitlisted Jobs(
                      {candidates.length - approvedCount - rejectedCount})
                    </button>
                    <button
                      className="bg-white py-1 px-4 rounded-2xl leading-8"
                      onClick={handleShowApproved}
                    >
                      Approved({approvedCount})
                    </button>
                    <button
                      className="bg-white py-1 px-4 rounded-2xl leading-8"
                      onClick={handleShowRejected}
                    >
                      Rejected({rejectedCount})
                    </button>
                  </div>

                  <div className="flex flex-row justify-between items-center ">
                    <div className="text-center sm:text-lg text-base font-medium">
                      <span>Show</span>
                      <span className="mx-2 rounded-2xl py-1 px-3 bg-[#DFDCF7]">
                        00&rarr;
                      </span>
                      <span>per page</span>
                    </div>

                    <div className="flex items-center text-black-600">
                      <button className="font-bold text-xl">&larr;</button>
                      <span className="mx-2 rounded-2xl py-1 px-5 h-8 w-12 bg-[#DFDCF7]">
                        1
                      </span>
                      <button className="font-bold text-xl">&rarr;</button>
                    </div>
                  </div>

                  <div>
                    {filteredCandidates.map((candidate) => (
                      <CandidateCard
                        key={candidate.id}
                        {...candidate}
                        updateJobStatus={updateJobStatus}
                        PropToEdit={PropToEdit}
                      />
                    ))}
                  </div>

                  <div className="flex flex-row justify-between items-center mt-4">
                    <div className="text-center sm:text-lg text-base font-medium">
                      <span>Show</span>
                      <span className="mx-2 rounded-2xl py-1 px-3 bg-[#DFDCF7]">
                        00&rarr;
                      </span>
                      <span>per page</span>
                    </div>

                    <div className="flex items-center text-black-600">
                      <button className="font-bold text-xl">&larr;</button>
                      <span className="mx-2 rounded-2xl py-1 px-5 h-8 w-12 bg-[#DFDCF7]">
                        1
                      </span>
                      <button className="font-bold text-xl">&rarr;</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Section5;
