import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import { toast } from "react-toastify";
import Navemployee from "../Sections/Navbar-3&5/Navbar_employee";
import { FaAngleRight } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { FaMoneyBills } from "react-icons/fa6";

const Frame10 = ({ applied = "false" }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [allowRender, setAllowRender] = useState(false);

  // const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    category: "",
    location: "",
    company: "",
  });
  const isApplied = applied === "true";
  if (applied !== "true") applied = "false";
  // applied = applied === true;

  useEffect(() => {
    let isMounted = true;
    const getJobs = async () => {
      try {
        const profileResponse = await axios.post(
          `${process.env.REACT_APP_PORT}/api/updateprofiledata`,
          { type: process.env.REACT_APP_TYPE_EMPLOYER },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!profileResponse.data.name) {
          navigate("/first_details");
          return;
        }

        // console.log("Apply in Frame -10 : ", applied)

        const jobsResponse = await axios.post(
          `${process.env.REACT_APP_PORT}/api/allJobs`,
          { apply: applied },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (isMounted) {
          setAllowRender(true);
          const jobData = jobsResponse.data;
          setJobs(jobData);
          setFilteredJobs(jobData);

          // Extract unique filter values
          setCategories([...new Set(jobData.map((job) => job.category))]);
          setLocations([...new Set(jobData.map((job) => job.jobLocation))]);
          setCompanies([...new Set(jobData.map((job) => job.companyName))]);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
        // toast.error("Please try again later.");
        if (!toast.isActive("invalid-credentials-toast")) {
          toast.error("Session Timeout Please Login Again", {
            toastId: "invalid-credentials-toast",
          });
        }
        localStorage.clear();
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    getJobs();

    return () => {
      isMounted = false;
    };
  }, [applied, token, navigate]);

  const applyFilters = () => {
    const filtered = jobs.filter((job) => {
      return (
        (selectedFilters.category === "" ||
          job.category === selectedFilters.category) &&
        (selectedFilters.location === "" ||
          job.jobLocation === selectedFilters.location) &&
        (selectedFilters.company === "" ||
          job.companyName === selectedFilters.company)
      );
    });
    setFilteredJobs(filtered);
  };

  const handleFilterChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  if (!allowRender) {
    return null; // Don't render anything until checks are done
  }

  return (
    <>
      <div className="w-full overflow-x-hidden min-h-screen font-inter ">
        {loading ? (
          <HashLoader
            color="#34a5f3"
            size={60}
            cssOverride={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            loading={true}
          />
        ) : (
          <div className="">
            {!isApplied && (
              <div className="">
                <div className="">
                  <Navemployee />
                </div>
                <div className="text-center text-white bg-primary-blue p-4 md:w-screen md:block hidden mb-4 rounded-b-xl ">
                  <h2 className="text-xl lg:text-3xl font-bold mt-1">
                    "GET HIRED IN 3 EASY STEPS"
                  </h2>

                  <div className="relative flex justify-evenly items-center mt-3 mb-1 lg:text-xl md:text-lg">
                    <div className="flex items-center relative">
                      <span>1) Select Job</span>
                    </div>

                    <div className="flex items-center lg:w-32">
                      <img
                        src="./Arrow1.png"
                        alt="Arrow"
                        className="h-10  transform -rotate-[30deg]"
                      />
                    </div>

                    <div className="flex flex-col items-center relative">
                      <span>2) Apply by filling basic details</span>
                    </div>

                    <div className="flex items-center lg:w-32">
                      <img
                        src="./Arrow1.png"
                        alt="Arrow"
                        className="h-10  transform -rotate-[30deg]"
                      />
                    </div>

                    <div className="flex flex-col items-center">
                      <span>3) Give interview and get hired easily</span>
                    </div>
                  </div>
                </div>

                <div className="w-full gap-8 mb-2 flex justify-center items-center xs:hidden mt-4 ">
                  <div className="flex items-center  justify-center w-4/5 bg-blue-50 border rounded-full px-2 py-1">
                    <FaSearch className="ml-2 h-5 w-5" />
                    <input
                      type="text"
                      name="search for jobs"
                      placeholder="search for jobs"
                      className="w-full placeholder-primary-blue placeholder-text-base  text-base text-center text-black bg-transparent border-none ml-2 bg-blue-50 focus:outline-none"
                      required
                    />
                  </div>
                </div>

                {/* {error && <div className="text-red-500">{error}</div>} */}
                <div className=" flex justify-start md:justify-start md:ml-12  mb-4 flex-wrap mt-2 ml-2 ">
                  {/* Category Filter */}
                  <button className="flex items-center text-[#393285] px-4 py-2  rounded-lg mb-2 gap-4">
                    <div className=" bg-[#D9D9D9] w-7 h-7 md:h-9 md:w-10 flex justify-center items-center rounded-lg">
                      <FaFilter className=" md:h-5 md:w-5 text-black" />
                    </div>
                    <div className="bg-[#D9D9D9] md:ml-2  px-1 py-1 md:py-[5px] rounded-lg md:rounded-xl text-sm md:text-lg font-medium md:w-52 w-36">
                      <select
                        className="bg-[#D9D9D9] focus:outline-none w-full"
                        onChange={(e) =>
                          handleFilterChange("category", e.target.value)
                        }
                        value={selectedFilters.category}
                      >
                        <option value="" className="text-xsm md:text-sm">
                          All Categories
                        </option>
                        {categories.map((category, index) => (
                          <option
                            key={index}
                            value={category}
                            className="text-xs md:text-xs"
                          >
                            {category}
                          </option>
                        ))}
                      </select>
                    </div>
                  </button>

                  {/* Location Filter */}
                  <button className="flex items-center bg-gray-200 text-[#393285] px-2 py-2 rounded-lg  mb-2">
                    {/* <RiArrowDownSLine /> */}
                    <div className="bg-[#D9D9D9] md:ml-2  px-1 py-1 md:py-[5px] rounded-lg md:rounded-xl text-sm md:text-lg font-medium md:w-48 w-36">
                      <select
                        className="bg-[#D9D9D9] focus:outline-none w-full"
                        onChange={(e) =>
                          handleFilterChange("location", e.target.value)
                        }
                        value={selectedFilters.location}
                      >
                        <option value="" className="text-xsm md:text-sm">
                          Location
                        </option>
                        {locations.map((location, index) => (
                          <option
                            key={index}
                            value={location}
                            className="text-xs md:text-xs"
                          >
                            {location}
                          </option>
                        ))}
                      </select>
                    </div>
                  </button>

                  {/* Company Filter */}
                  <button className="flex items-center bg-gray-200 text-[#393285] px-2 py-2 rounded-lg mr-2 mb-2">
                    {/* <RiArrowDownSLine /> */}
                    <div className="bg-[#D9D9D9] md:ml-2  px-1 py-1 md:py-[5px] rounded-lg md:rounded-xl text-sm md:text-lg font-medium md:w-52 w-36">
                      <select
                        className="bg-[#D9D9D9] focus:outline-none w-full"
                        onChange={(e) =>
                          handleFilterChange("company", e.target.value)
                        }
                        value={selectedFilters.company}
                      >
                        <option value="" className="text-sm">
                          All Companies
                        </option>
                        {companies.map((company, index) => (
                          <option
                            key={index}
                            value={company}
                            className="text-sm"
                          >
                            {company}
                          </option>
                        ))}
                      </select>
                    </div>
                  </button>
                </div>
              </div>
            )}
            <div className="container mx-auto w-[100%]">
              <div className="grid grid-cols-1 sm:grid-cols-2 xmd:grid-cols-3 justify-items-center  sm:gap-x-3 gap-x-2 gap-y-3  text-sm md:text-base xmd:text-lg ">
                {filteredJobs
                  .filter((job) => job.status === "accepted")
                  .map((job) => (
                    <div
                      key={job.id}
                      className="relative bg-blue-50 p-2 rounded-3xl shadow-md xl:w-96 xlg:w-80 lg:w-[300px] xmd:w-[280px] mmd:w-96 md:w-80 sm:w-[300px] xs:w-96 xsm:w-80 w-[280px]  mb-3 overflow-hidden truncate"
                      onClick={() =>
                        navigate(`/job_details/${job.id}`, {
                          state: { alreadyApply: applied },
                        })
                      }
                    >
                      <FaAngleRight className="absolute right-2 text-primary-blue h-8 w-8 cursor-pointer" />
                      <div className="w-full flex items-center mb-3">
                        <div className="h-12 w-12 rounded-full border-2 border-gray-800">
                          <img
                            className="h-full w-full rounded-full object-cover"
                            src={job.profile ? job.profile : "/profile.jpg"}
                            alt="Company Logo"
                            name="profile"
                          />
                        </div>
                        <h3 className="pl-4 font-medium  w-3/4">
                          <span className="block  items-center  overflow-hidden text-ellipsis whitespace-nowrap text-base">
                            {job.jobTitle}
                          </span>

                          <span className="block  text-secondary-blue overflow-hidden text-ellipsis whitespace-nowrap text-base">
                            {job.companyName}
                          </span>
                        </h3>
                      </div>
                      <div className="w-full flex items-center mt-4 mb-1 ml-3 text-sm">
                        <div className="text-xl">
                          <IoLocationSharp/>
                        </div>
                        <h3 className="pl-4 font-normal w-3/4">
                          <span className="block  overflow-hidden text-ellipsis whitespace-nowrap">
                            {job.jobLocation}
                          </span>
                        </h3>
                      </div>
                      <div className="w-full flex items-center mb-4 ml-3 text-sm ">
                        <div className="text-xl">
                        <FaMoneyBills/>
                        </div>
                        <h3 className="pl-4 font-normal  w-3/4">
                          <span className="block  overflow-hidden text-ellipsis whitespace-nowrap ">
                            {job.salary}
                          </span>
                        </h3>
                      </div>
                      <div className="w-full flex items-center mb-1">
                        <button className="mt-1 bg-white text-[#393285] rounded-full px-3 py-1 border border-[#393285] overflow-hidden truncate text-base">
                          <p className="">click here for details</p>
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Frame10;
