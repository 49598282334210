import React, { useEffect, useState } from "react";
import axios from "axios";
// import NotFound from "../UnknowRoute/NotFound";
import HashLoader from "react-spinners/HashLoader";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
// import { jobsData } from "../../data/jobsData";
import { FaArrowLeftLong } from "react-icons/fa6";
import Navbar from "../Sections/Navbar-3&5/navbar1";

// Example Navbar1 component

const CandidateCard = ({
  id,
  companyName,
  companyAddress,
  organizationAddress,
  jobTitle,
  jobLocation,
  jobState,
  jobCity,
  jobPincode,
  educationQualification,
  experienceFresher,
  experienceLevel,
  interviewLocation,
  nameAndDesignation,
  designation,
  mobileNumber,
  email,
  workType,
  languagesKnown,
  salary,
  gender,
  candidateGender,
  workingHours,
  jobDescription,
  appliedDate,
  status,
  interviewProcess,
  interviewState,
  interviewCity,
  interviewPincode,
  interviewTiming,
  updateJobStatus,
  postedDate,
  profile, // Pass updateJobStatus as a prop
}) => {
  const navigate = useNavigate();
  const handleCheckResponses = (documentId, jobTitle) => {
    navigate(`/responses/${documentId}`, { state: jobTitle });
  };
  const location = useLocation();
  const jobId = location.state.jobId;

  const [showDesc, setShowDesc] = useState(false);

  const handleDesc = () => {
    setShowDesc((prev) => !prev);
  };

  const handleWaiting = () => {
    Swal.fire({
      title: "Under Progress",
      text: "Admin is verifying your details on progress",
      icon: "info",
      confirmButtonText: "Got it!", // Add a custom button
      confirmButtonColor: "#3085d6", // Change the button color
    });
  };

  const formattedDate = new Date(postedDate).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  return (
    <>
      {/* ------------- new code  ------------------- */}

      <div className=" sm:p-4 py-4 sm:px-8 px-4  rounded-2xl shadow-md mb-4 bg-[#DFDCF7]  flex flex-col xmd:flex-row gap-x-2 gap-y-4 xmd:gap-x-0 justify-between  w-full xl:w-[90%] mx-auto">
        {/* ------- profile ------- */}

        <div className="xmd:w-max w-full  flex justify-center ">
          <img
            src={profile ? profile : "/profile.jpg"} // Adjusted fallback path
            alt="Profile"
            className=" rounded-full sm:mb-4  w-16 h-16 sm:h-14 sm:w-14 md:h-16 md:w-16  p-1 border border-black-10"
          />
        </div>

        {/* --------- job details --------- */}

        <div className="w-full xmd:w-[75%] xl:w-[70%]  flex flex-col gap-y-4 ">
          {/* ------- Basic details ----- */}

          <div className="flex sm:flex-row flex-col gap-y-2 justify-between  ">
            <div className="xlg:w-[32%] xmd:w-[48%] sm:w-[50%]  flex flex-col gap-y-2 ">
              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Company Name</p>
                <p>{companyName}</p>
              </div>

              <div className="flex xlg:hidden flex-col ">
                <p className="font-semibold text-blue-200">Email</p>
                <p>{email}</p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Job Title</p>
                <p>{jobTitle}</p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Job Location</p>
                <p className="w-full flex gap-x-1 flex-wrap">
                  <span>{jobLocation},</span>
                  <span>
                    {jobCity}-{jobPincode},
                  </span>
                  <span>{jobState}</span>
                </p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">
                  Education Qualification
                </p>
                <p>
                  {Array.isArray(educationQualification)
                    ? educationQualification
                        .map((data) =>
                          data === "10thPassed"
                            ? "10th Passed"
                            : data === "12thPassed"
                            ? "12th Passed"
                            : "Graduated and above"
                        )
                        .join(", ")
                    : educationQualification === "10thPassed"
                    ? "10th Passed"
                    : educationQualification === "12thPassed"
                    ? "12th Passed"
                    : "Graduated and above"}
                </p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Company Address</p>
                <p>{organizationAddress}</p>
              </div>

              <div className="flex xlg:hidden flex-col ">
                <p className="font-semibold text-blue-200">Working Hours</p>
                <p>{workingHours} hrs</p>
              </div>

              {interviewLocation && (
                <div className="flex flex-col ">
                  <p className="font-semibold text-blue-200">
                    Interview Location
                  </p>
                  <p className="w-full flex gap-x-1 flex-wrap">
                    <span>{interviewLocation},</span>
                    <span>
                      {interviewCity}-{interviewPincode},
                    </span>
                    <span>{interviewState}</span>
                  </p>
                </div>
              )}
            </div>

            <div className="xlg:w-[32%] xmd:w-[48%] sm:w-[50%] flex flex-col gap-y-2 ">
              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Mobile Number</p>
                <p>{mobileNumber}</p>
              </div>

              <div className="flex xlg:hidden flex-col ">
                <p className="font-semibold text-blue-200">Gender</p>
                <p className="flex flex-col gap-y-0">
                  {gender ? (
                    gender === "Both" ? (
                      "Male and Female"
                    ) : (
                      gender
                    )
                  ) : (
                    <span>Male and Female</span>
                  )}
                </p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Designation</p>
                <p>{designation}</p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Experience Level</p>
                <p>
                  {" "}
                  {Array.isArray(experienceLevel)
                    ? experienceLevel
                        .map((data) =>
                          data === "fresher"
                            ? "Fresher"
                            : data === "0-1yr"
                            ? "0-1 yr"
                            : data === "1-2yr"
                            ? "1-2 yr"
                            : "More than 2 yr"
                        )
                        .join(", ")
                    : experienceLevel === "fresher"
                    ? "Fresher"
                    : experienceLevel === "0-1yr"
                    ? "0-1 yr"
                    : experienceLevel === "1-2yr"
                    ? "1-2 yr"
                    : "More than 2 yr"}
                </p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Work Type</p>
                <p>
                  {" "}
                  {Array.isArray(workType)
                    ? workType
                        .map((data) =>
                          data === "hybrid"
                            ? "Hybrid"
                            : data === "home"
                            ? "Work from Home"
                            : "Work from Office"
                        )
                        .join(", ")
                    : workType === "hybrid"
                    ? "Hybrid"
                    : workType === "home"
                    ? "Work from Home"
                    : "Work from Office"}
                </p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Interview Process</p>
                <p>
                  {Array.isArray(interviewProcess)
                    ? interviewProcess.join(", ")
                    : interviewProcess}
                </p>
              </div>

              <div className="flex xlg:hidden flex-col ">
                <p className="font-semibold text-blue-200">Salary</p>
                <p>{salary}</p>
              </div>

              {interviewTiming && (
                <div className="flex xlg:hidden flex-col ">
                  <p className="font-semibold text-blue-200">
                    Interview Timing
                  </p>
                  <p>{interviewTiming}</p>
                </div>
              )}
            </div>

            <div className="w-[32%] hidden xlg:flex flex-col gap-y-2 ">
              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Email</p>
                <p>{email}</p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Gender</p>
                <p className="flex flex-col gap-y-0">
                  {gender ? (
                    gender === "Both" ? (
                      "Male and Female"
                    ) : (
                      gender
                    )
                  ) : (
                    <span>Male and Female</span>
                  )}
                </p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Working Hours</p>
                <p>{workingHours} hrs</p>
              </div>

              <div className="flex flex-col ">
                <p className="font-semibold text-blue-200">Salary</p>
                <p>{salary}</p>
              </div>

              {interviewTiming && (
                <div className="flex flex-col ">
                  <p className="font-semibold text-blue-200">
                    Interview Timing
                  </p>
                  <p>{interviewTiming}</p>
                </div>
              )}
            </div>
          </div>

          {/* ------- Description  ------ */}

          <div className=" flex flex-col gap-y-2 ">
            <p className="font-semibold text-blue-200">Job Description</p>

            <pre className="font-inter" style={{ whiteSpace: "pre-wrap" }}>
              {jobDescription
                ? !showDesc
                  ? jobDescription.length > 200
                    ? jobDescription.slice(0, 200) + "... "
                    : jobDescription
                  : jobDescription + "... "
                : "No description available."}

              {jobDescription.length > 200 && (
                <span
                  onClick={handleDesc}
                  className="text-blue-300 cursor-pointer font-bold"
                >
                  {showDesc ? "show less" : "read more"}
                </span>
              )}
            </pre>
          </div>
        </div>

        {/* ------- response btn -------- */}

        <div className="xmd:w-max w-full flex flex-col justify-between items-center gap-y-6 ">
          <button
            className={`flex w-max justify-center py-2  px-3 bg-white rounded-2xl transition-all duration-300 hover:scale-105 text-base  ${
              status === "rejected"
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer hover:bg-gray-200 hover:shadow-xl transition-shadow duration-200"
            }`}
            onClick={() => {
              if (status === "accepted") {
                handleCheckResponses(jobId, jobTitle);
              } else if (status === "waiting") {
                handleWaiting();
              }
            }}
            disabled={status === "rejected"} // Disable button if status is rejected
          >
            Check Response
          </button>

          <div className="flex flex-col w-full items-end  smd:items-center font-semibold">
            <p className="text-blue-200">Posted on</p>
            <p className="text-gray-500 text-sm  ">{formattedDate}</p>
          </div>
        </div>
      </div>
    </>
  );
};

const Section8 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const uid = localStorage.getItem("token");
  //   const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allowRender, setAllowRender] = useState(false);
  const [job, setJob] = useState(null);
  //   const jobTitle = location.state;
  //   const jobId = location.jobId;
  const { jobId, jobTitle } = location.state || {}; // Access state safely

  //   const navigate = useNavigate();

  useEffect(() => {
    // Fetch job details from the API
    const fetchJob = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PORT}/api/getJobData`,
          { jobId: jobId }, // Pass the job ID as a payload
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${uid}`, // Include authentication token
            },
          }
        );
        setJob(response.data); // Update state with the fetched job
        setAllowRender(true);
      } catch (err) {
        console.error("Error fetching job details:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchJob();
  }, [jobId, uid]);

  if (!allowRender) {
    return null;
  }

  return (
    <>
      <div>
        {loading ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
            loading={true}
          />
        ) : (
          <div className="w-full">
            <Navbar />
            <div className="w-[95%] xl:mt-12 mt-8 mx-auto flex flex-col  gap-y-2  justify-between items-start ">
              {/* -------- back btn ---------- */}
              <div
                onClick={() => navigate(-1)}
                className="xl:w-[10%] w-max  flex items-center gap-x-2  cursor-pointer "
              >
                <span className="text-base">
                  <FaArrowLeftLong />
                </span>
                <p className="l xmd:text-xl text-lg">Back</p>
              </div>

              {/* ---------- job posting part -------- */}

              <div className="w-full flex flex-col lg:gap-y-8  xs:gap-y-6 gap-y-4">
                {/* ------ job title ------ */}

                <div className="flex items-center justify-center">
                  <h2 className="text-center lg:text-[28px] text-xl md:font-semibold font-bold text-blue-200 uppercase">
                    {jobTitle}
                  </h2>
                </div>

                {/* --------- job details ------- */}

                <div className="flex flex-col xs:gap-y-6 gap-y-4 w-[90%] sm:w-full mx-auto">
                  {job ? (
                    <CandidateCard
                      key={job.id}
                      {...job}
                      //   updateJobStatus={updateJobStatus}
                    />
                  ) : (
                    <div>No job found.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Section8;
