import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useParams } from "react-router-dom";
import Navemployee from "../Sections/Navbar-3&5/Navbar_employee";
import axios from "axios";
import Swal from "sweetalert2";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
function Section2() {
  function handleaSucessclick() {
    Swal.fire({
      title: "Success",
      text: "Your Profile has been submitted successfully for the job",

      icon: "success",
      confirmButtonText: "ok",
    }).then((result) => {
      if (result.isConfirmed) {
        navigation(`/job_details/${jobId}`, {
          state: { alreadyApply: "true" },
        });
      }
    });
  }
  const navigation = useNavigate();

  const token = localStorage.getItem("token");
  const [loadin, setLoadin] = useState(true); // State to track loading
  const [profileImageURL, setProfileImageURL] = useState("/profile.jpg"); // Default fallback image
  const [resumePdfURL, setResumePdfURL] = useState(""); // Default fallback image
  const [allowRender, setAllowRender] = useState(false);

  const { jobId } = useParams();
  const [formData, setFormData] = useState({
    uid: token,
    name: "",
    salary: Number(""),
    profile: null,
    resumePdf: null,
    video: null,
    govtId: null,
    email: "",
    gender: "",
    mobileNumber: Number(""),
    currentAddress: "",
    selectedState: "",
    selectedCity: "",
    dob: "",
    workexperience: Number(""),
    pincode: Number(""),
    education: "",
    pastjobexperience: "",
    language: "",
    skills: "",
    status: "waiting",
  });
  // const formData = {
  //   uid: "sampleToken123",
  //   name: "John Doe",
  //   salary: 50000,
  //   profile: null,
  //   resumePdf: null,
  //   video: null,
  //   govtId: null,
  //   email: "johndoe@example.com",
  //   gender: "Male",
  //   mobileNumber: 9876543210,
  //   currentAddress: "123 Main Street, City, State",
  //   selectedState: "California",
  //   selectedCity: "Los Angeles",
  //   dob: "1990-01-01",
  //   workexperience: 5,
  //   pincode: 90001,
  //   education: "Bachelor's Degree",
  //   pastjobexperience: "Software Developer at XYZ Corp",
  //   language: "English, Spanish",
  //   skills: "JavaScript, React, Node.js",
  //   status: "waiting",
  // };

  let timerInterval;
  let loading = false;
  function handleProgress() {
    Swal.fire({
      title: "Profile has been updating .....",
      html: "",
      timerProgressBar: loading,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log("I was closed by the timer");
      }
    });
  }

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PORT}/api/updateprofiledata`,
          {
            type: process.env.REACT_APP_TYPE_EMPLOYER,
            route: "section2",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Uncomment if authorization is needed
            },
          }
        );

        const profileData = response.data;
        console.log("Profile Data:", profileData);
        if (!response.data.message) {
          setFormData(profileData);
          setAllowRender(true);

          if (profileData.profile) {
            setProfileImageURL(profileData.profile);
          }
          if (profileData.resume) {
            setFormData({
              ...profileData,
              resumePdf: profileData.resume,
            });
            setResumePdfURL(profileData.resume);
          }
        }
      } catch (err) {
        // console.log("Error fetching profile data:", err);
        // localStorage.removeItem("token");
        if (!toast.isActive("invalid-credentials-toast")) {
          toast.error("Invalid Credentials", {
            toastId: "invalid-credentials-toast",
          });
        }
        navigation("/");
      } finally {
        setLoadin(false);
      }
    };

    fetchProfileData(); // Call the function once the token is available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, navigation]);

  if (!allowRender) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChang = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImageURL(imageUrl);
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0], // Only one file
    });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const resumeUrl = URL.createObjectURL(file);
      setResumePdfURL(resumeUrl);
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0], // Only one file
    });
  };

  const validateForm = () => {
    const {
      // profile,
      name,
      email,
      gender,
      mobileNumber,
      currentAddress,
      dob,
      selectedState,
      selectedCity,
      workexperience,
      pincode,
      education,
      language,
      resumePdf,
      // skills,
    } = formData;

    // if (!profile) return "Profile is required";
    if (!name) return "Name is required";
    if (!email || !/\S+@\S+\.\S+/.test(email)) return "Valid email is required";
    if (!gender) return "Gender is required";
    if (!mobileNumber || !/^\d{10}$/.test(mobileNumber))
      return "Valid 10-digit number is ";
    if (!currentAddress) return "Address is required";
    if (!dob) return "Date of Birth is required";
    if (!selectedState) return "State is required";
    if (!selectedCity) return "City is required";
    if (!workexperience) return "Work Experience is required";
    if (!pincode || !/^\d{6}$/.test(pincode))
      return "Valid Pincode is required";
    if (!education) return "Education is required ";
    if (!language) return "Language is required";
    if (!resumePdf) return "Resume is required";
    // if (!skills) return "Skills is required";

    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();

    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    if (age < 18) return "You must be at least 18 years old";

    return null; //No errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (errors) {
      toast.warn(errors);
      return;
    }

    // const formDataToSend = new FormData();
    formData.jobId = jobId;
    // Object.entries(formData).forEach(([key, value]) => {
    //   if (value !== null) {
    //     formDataToSend.append(key, value);
    //     // formDataToSend.append("jobId", jobId);
    //   }
    // });

    loading = true;
    handleProgress();
    try {
      await axios.post(`${process.env.REACT_APP_PORT}/api/uploa`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Uncomment if authorization is needed
        },
      });

      handleaSucessclick();
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <>
      <div className="font-inter h-full w-full  overflow-x-hidden">
        {loadin ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
            loading={true}
          />
        ) : (
          <div>
            <Navemployee />
            <div>
              <div className="flex justify-center mt-4  px-2">
                <h2 className="text-2xl sm:text-3xl  font-bold text-primary-blue sm:ml-8 text-center">
                  Fill Your Details to Apply for Jobs Directly
                </h2>
              </div>
              <form
                onSubmit={handleSubmit}
                className=" flex justify-center items-center "
              >
                <div className=" w-full flex flex-col sm:flex-row items-center justify-between mt-4 gap-1 ">
                  <div className="flex items-center justify-center sm:justify-end sm:w-2/6 sm:self-baseline">
                    <div className="h-20 w-20 sm:mr-8 sm:mt-4">
                      {/* Display profile image */}
                      <img
                        className="h-full w-full rounded-full border border-gray object-cover cursor-pointer"
                        src={profileImageURL}
                        alt="Profile"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        } // Ensure the ID matches
                      />

                      {/* Hidden file input */}
                      <input
                        id="fileInput" // Use the correct ID
                        type="file" // Correct the type to "file"
                        name="profile"
                        accept="image/*" // Ensure you specify valid input types
                        className="hidden" // Hide the input visually
                        onChange={handleFileChang} // Handle file selection
                      />
                    </div>
                  </div>

                  <div className=" realtive w-full sm:w-4/6 mx-auto  ">
                    <div className="flex flex-col sm:flex-row sm:justify-start sm:items-center gap-3 mb-2 flex-wrap justify-center items-center mx-auto mt-3">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your Full name*"
                        className="placeholder-black  placeholder:text-ssm   bg-blue-10  text-sm sm:text-base text-center text-black  rounded-full py-2 px-2 w-[75%] sm:w-[25%] "
                      />
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email ID*"
                        className="placeholder-black placeholder:text-ssm   bg-blue-10 text-sm sm:text-base text-center text-black rounded-full py-2 px-2 w-[75%] sm:w-[25%] "
                      />

                      <input
                        type="text"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className="placeholder-black placeholder:text-ssm    bg-blue-10 text-sm sm:text-base text-center text-black rounded-full py-2 px-2 w-[75%] sm:w-[23%] "
                        placeholder="Gender"
                      />
                    </div>
                    <div className="flex flex-col sm:flex-row sm:justify-start sm:items-center gap-3 mb-2 justify-center items-center sm:mt-3">
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        placeholder="Mobile Number"
                        className="placeholder-black placeholder:text-ssm    bg-blue-10 text-sm sm:text-base text-center text-black rounded-full py-2 px-2 w-[75%] sm:w-[25%]   "
                      />
                      <input
                        type="text"
                        name="currentAddress"
                        value={formData.currentAddress}
                        onChange={handleChange}
                        placeholder="Company Address"
                        className="placeholder-black placeholder:text-ssm    bg-blue-10  text-sm sm:text-base text-center text-black rounded-full py-2 px-3 w-[75%] sm:w-[50%] "
                      />
                    </div>
                    <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 mb-2 justify-center items-center  sm:mt-3 ">
                      <input
                        type="date"
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                        placeholder="Date of Birth*"
                        className="placeholder-black placeholder:text-ssm   bg-blue-10 text-sm sm:text-base text-center text-black  rounded-full py-2 px-2 w-[75%] sm:w-[33%]  "
                      />
                      <div className="gap-3 w-full flex flex-row justify-center items-start sm:justify-start">
                        <input
                          type="text"
                          name="selectedState"
                          value={formData.selectedState}
                          onChange={handleChange}
                          placeholder="State*"
                          className="placeholder-black placeholder:text-ssm  placeholder:text-ssm  bg-blue-10 text-sm sm:text-base text-center text-black  rounded-full py-2 px-2 w-[36%] sm:w-[25%] "
                        />
                        <input
                          type="text"
                          name="selectedCity"
                          value={formData.selectedCity}
                          onChange={handleChange}
                          placeholder="City*"
                          className="placeholder-black  placeholder:text-ssm  bg-blue-10 text-sm sm:text-base text-center text-black  rounded-full py-2 px-2 w-[36%] sm:w-[25%]  "
                        />
                      </div>
                    </div>
                    <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 mb-2 justify-center items-center sm:mt-3">
                      {/* <input
                        type="text"
                        name="workexperience"
                        value={formData.workexperience}
                        onChange={handleChange}
                        placeholder="Work Experience"
                        className="placeholder-black placeholder:text-ssm  placeholder:text-ssm  bg-blue-10 text-sm sm:text-base text-center text-black border rounded-full py-2 px-2 w-[75%] sm:w-[33%]  "
                      /> */}
                      <select
                        name="workexperience"
                        value={formData.workexperience}
                        onChange={handleChange}
                        className="bg-blue-10 text-sm sm:text-base text-center text-black  rounded-full py-2 px-2 w-[75%] sm:w-[33%]"
                      >
                        <option value="" disabled>
                          Select Work Experience
                        </option>
                        <option value="fresher">Fresher</option>
                        <option value="0-1 yr">0-1 yr</option>
                        <option value="1-2 yr">1-2 yr</option>
                        <option value="more than 2 years">
                          More than 2 years
                        </option>
                      </select>

                      <div className="gap-3 w-full flex flex-row justify-center items-start sm:justify-start">
                        <input
                          type="text"
                          name="pincode"
                          value={formData.pincode}
                          onChange={handleChange}
                          placeholder="Pincode"
                          className="placeholder-black placeholder:text-ssm  placeholder:text-ssm  bg-blue-10  text-sm sm:text-base text-center text-black  rounded-full py-2 px-2 w-[36%] sm:w-[25%] "
                        />
                        <input
                          type="text"
                          name="education"
                          value={formData.education}
                          onChange={handleChange}
                          placeholder="Education Qualification"
                          className="placeholder-black placeholder:text-ssm    bg-blue-10 text-sm sm:text-base text-center text-black  rounded-full py-2 px-2 w-[36%] sm:w-[25%] "
                        />
                      </div>
                    </div>
                    <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 mb-2 justify-center items-center sm:mt-3">
                      {["0-1 yr", "1-2 yr", "more than 2 years"].includes(
                        formData.workexperience
                      ) && (
                        <textarea
                          name="pastjobexperience"
                          value={formData.pastjobexperience}
                          onChange={handleChange}
                          placeholder="  Share your past/current job experience"
                          className="placeholder-black placeholder:text-ssm bg-blue-10 text-sm sm:text-base text-center text-black  rounded-2xl py-2 px-2 resize-none w-[75%] h-[15vh]"
                        />
                      )}
                    </div>
                    <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 sm:gap-8 mb-2 justify-center items-center">
                      {/* Text Input for Languages */}
                      <input
                        type="text"
                        name="language"
                        value={formData.language}
                        onChange={handleChange}
                        placeholder="Languages known"
                        className="placeholder-black placeholder:text-ssm  bg-blue-10 text-sm sm:text-base text-center text-black  rounded-full py-2 px-2  w-[75%] sm:w-[32%]"
                      />

                      {/* File Input for Resume */}
                      <label
                        htmlFor="resume-upload"
                        className="placeholder-black placeholder:text-ssm  bg-blue-10 text-sm sm:text-base text-center text-black  rounded-full py-2 px-2 w-[75%] sm:w-[32%] cursor-pointer"
                      >
                        {formData.resumePdf
                          ? "Change File"
                          : "Upload your CV/Resume"}
                        <input
                          id="resume-upload"
                          type="file"
                          name="resumePdf"
                          accept="application/pdf"
                          onChange={handleFileChange}
                          className="hidden"
                        />
                      </label>
                      {formData.resumePdf && (
                        <div className="file-preview mt-3">
                          <a
                            href={resumePdfURL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary-blue underline text-sm sm:text-base"
                          >
                            View Resume
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 mb-2 justify-center items-center sm:mt-3">
                      <textarea
                        type="text"
                        name="skills"
                        value={formData.skills}
                        onChange={handleChange}
                        placeholder="Mention your any other skills "
                        className="placeholder-black placeholder:text-ssm  bg-blue-10 text-sm sm:text-base  text-center text-black  rounded-2xl py-2 px-2 resize-none  w-[75%]  sm:w-[50%] h-[10vh]"
                      />
                    </div>{" "}
                    {/* <div className="bg-red-700 w-[42vw] flex flex-col sm:flex-row mt-4 justify-center items-center sm:justify-start sm:items-start"> */}
                    {/* <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 sm:gap-8 mb-2 justify-center items-center">
                      <label
                        htmlFor="govt-id-upload"
                        className="bg-blue-10 placeholder:text-ssm  text-sm sm:text-base text-center text-black border rounded-full py-2 px-2 sm:w-[32%] w-[75%] cursor-pointer"
                        >
                        {formData.govtId
                          ? "Change File"
                          : "Upload any Govt. ID proof"}
                        <input
                          id="govt-id-upload"
                          type="file"
                          name="govtId"
                          accept="application/pdf,image/*"
                          onChange={handleFileChange}
                          className="hidden"
                        />
                      </label>
                      {formData.govtId && (
                        <div className="file-preview">
                          <a
                            href={formData.govtId}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary-blue underline text-sm sm:text-base"
                            >
                            Govt ID
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 sm:gap-8 mb-2 justify-center items-center">
                      <label
                        htmlFor="video-upload"
                        className="bg-blue-10 text-sm sm:text-base text-center text-black border rounded-xl sm:rounded-full py-2 px-2 sm:w-[75%] w-[75%] cursor-pointer"
                        >
                        {formData.video
                          ? "Change File"
                          : "Upload your one-minute audio/video introduction for smooth selection"}
                        <input
                          id="video-upload"
                          type="file"
                          name="video"
                          accept="video/*"
                          onChange={handleFileChange}
                          className="hidden"
                        />
                      </label>
                      {formData.video && (
                        <div className="file-preview">
                          <a
                            href={formData.video}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary-blue underline text-sm sm:text-base"
                            >
                            Introductory Video
                          </a>
                        </div>
                      )}
                    </div> */}
                    {/* Add more input fields as needed */}
                    <div className="flex flex-col sm:flex-row sm:justify-center sm:items-start gap-3 sm:gap-8 mb-2 justify-center items-center">
                      <button
                        type="submit"
                        className="bg-primary-blue text-white  rounded-full py-2 px-8 hover:scale-110 transition-all duration-300"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Section2;
